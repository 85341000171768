export default [
  {
    "text": "Pointeuses",
    "route": "pointeuses",
    "icon": "ti-layout",
    "permission": "Voir la page des pointeuses"
  },
  {
    "text": "Entreprises",
    "route": "entreprises",
    "icon": "ti-layout",
    "permission": "Voir la page des pointeuses"
  },
  {
    "text": "Support Clients",
    "icon": "ti-file",
    "route": "",
    "submenu": [
      {
        "text": "Requettes",
        "icon": "ti-layout",
        "route": "supportclientrequettes",
        "params": {
          "type": "journal"
        },
        "permission": "Voir la page des pointages brutes"
      },
      {
        "text": "Reponses",
        "icon": "ti-layout",
        "route": "supportclientreponses",
        "params": {
          "type": "journal"
        },
        "permission": "Voir la page des pointages brutes"
      },
    ]
  },
  {
    "text": "Configurations",
    "icon": "ti-settings",
    "route": "configurations",
    "permission": "Voir la page de Configurations",
    "module": "Configuration",
  },
  {
    "text": "Administration utilisateurs ",
    "icon": "ti-lock",
    "route": "",
    "module": "Administration",
    "submenu": [
      {
        "text": "Les users",
        "route": "users",
        "icon": "ti-user",
        "permission": "Voir la page des utilisateurs"
      },
      {
        "text": "Les super admin",
        "route": "Users_SU",
        "icon": "ti-shield",
        "permission": "Voir la page des superAdmin",
        "isSu": true
      },
      {
        "text": "Roles / Profils",
        "route": "roles",
        "icon": "ti-layout",
        "params": {
          "type": "brute"
        },
        "permission": "Voir la page des roles"
      },
      {
        "text": "Permissions",
        "route": "permissions",
        "icon": "ti-layout",
        "isSu": true,
        "params": {
          "type": "brute"
        },
        "permission": "Voir la page des permissions"
      },
      {
        "text": "Logs",
        "route": "surveillances",
        "icon": "ti-layout",
        "permission": "Voir la page des activites recentes"
      },
      {
        "text": "Entreprises",
        "route": "entreprises",
        "icon": "ti-layout",
        "isSu": true
      }
    ]
  },
]
