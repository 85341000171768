export default [
  {
    "text": "Maps",
    "icon": "ti-clipboard",
    "route": "maps",
    "permission": "Voir la page d'acceuil",
    "modules": "Poste"
  },
  {
    "text": "SITES",
    "icon": "ti-settings",
    "route": "sites",
    "permission": "Voir la page d'acceuil",
    "module": "Effectif"
  },
  {
    "text": "MAINTENANCES",
    "icon": "ti-clipboard",
    "route": "maintenances",
    "permission": "Voir la page d'acceuil",
    "modules": "Poste"
  },
]
