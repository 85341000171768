export default [
  {
    "text": "Infos clients ",
    "icon": "ti-lock",
    "route": "",
    "module": "Administration",
    "submenu": [
      {
        "text": "Usagers",
        "icon": "ti-user",
        "route": "usagers",
        "permission": "Voir la page d'acceuil",
        "module": "Effectif"
      },
      {
        "text": "Cartes",
        "icon": "ti-user",
        "route": "cartes",
        "permission": "Voir la page d'acceuil",
        "module": "Effectif"
      },
    ]
  },
  {
    "text": "Transports ",
    "icon": "ti-lock",
    "route": "",
    "submenu": [
      {
        "text": "Vehicules",
        "icon": "ti-layout",
        "route": "vehicules",
        "params": {
          "type": "journal"
        },
        "permission": "Voir la page des pointages brutes"
      },
      {
        "text": "Trajets",
        "icon": "ti-layout",
        "route": "trajets",
        "params": {
          "type": "journal"
        },
        "permission": "Voir la page des pointages brutes"
      },
      {
        "text": "Lignes ",
        "icon": "ti-layout",
        "route": "lignes",
        "params": {
          "type": "journal"
        },
        "permission": "Voir la page des pointages brutes"
      },
    ]
  },
  {
    "text": "transactions ",
    "icon": "ti-layout",
    "route": "journals",
    "params": {
      "type": "journal"
    },
    "permission": "Voir la page des pointages brutes"
  },
  {
    "text": "Agences ",
    "icon": "ti-layout",
    "route": "sites",
    "params": {
      "type": "journal"
    },
    "permission": "Voir la page des pointages brutes"
  },
  {
    "text": "Forfaits ",
    "icon": "ti-layout",
    "route": "forfaits",
    "params": {
      "type": "journal"
    },
    "permission": "Voir la page des pointages brutes"
  },
  {
    "text": "Configurations",
    "icon": "ti-settings",
    "route": "configurations",
    "permission": "Voir la page de Configurations",
    "module": "Configuration",
  },
  {
    "text": "Administration utilisateurs ",
    "icon": "ti-lock",
    "route": "",
    "module": "Administration",
    "submenu": [
      {
        "text": "Les users",
        "route": "users",
        "icon": "ti-user",
        "permission": "Voir la page des utilisateurs"
      },
      {
        "text": "Les super admin",
        "route": "Users_SU",
        "icon": "ti-shield",
        "permission": "Voir la page des superAdmin",
        "isSu": true
      },
      {
        "text": "Roles / Profils",
        "route": "roles",
        "icon": "ti-layout",
        "params": {
          "type": "brute"
        },
        "permission": "Voir la page des roles"
      },
      {
        "text": "Permissions",
        "route": "permissions",
        "icon": "ti-layout",
        "isSu": true,
        "params": {
          "type": "brute"
        },
        "permission": "Voir la page des permissions"
      },
      {
        "text": "Logs",
        "route": "surveillances",
        "icon": "ti-layout",
        "permission": "Voir la page des activites recentes"
      },
      {
        "text": "Entreprises",
        "route": "entreprises",
        "icon": "ti-layout",
        "isSu": true
      }
    ]
  }
]
