import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// Modules
import app from './app'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState({
    key: 'my-app-store', // Clé utilisée pour stocker les données dans le localStorage
    paths: ['app.user'] // Chemins des modules/états à persister
  })]
})
